export const defaultBanner = {
  image: "path/to/default-banner-image.jpg",
  title: "Transforming Ideas into Cloud-Driven Realities",
};
export const defaultOfferings = [
  {
    title: "Cloud",
    description:
      "Scale and innovate with our cloud solutions tailored to your business needs.",
    services: [
      "Explore the full spectrum of cloud services: IaaS, PaaS, SaaS Development, migration, management, security, and disaster recovery. Elevate your infrastructure with custom solutions designed for efficiency and resilience.",
    ],
  },
  {
    title: "AI & ML",
    description:
      "Leverage the power of AI and machine learning in the cloud to drive innovation and efficiency.",
    services: [
      "Harness AI and machine learning to gain valuable insights, automate processes, and enhance user experiences. Let's build an AI and ML solution designed to transform your business operations.",
    ],
  },
  {
    title: "Web",
    description:
      "Forge compelling web experiences that captivate and convert your target audience.",
    services: [
      "Craft unique web solutions: Frontend and Backend Development, E-Commerce Platforms, CMS, API Development, and Performance Optimization. We prioritize user engagement and streamlined operations.",
    ],
  },
  {
    title: "Mobile",
    description:
      "Engage your audience anywhere with our cutting-edge mobile app solutions.",
    services: [
      "Build standout mobile apps with iOS and Android development, cross-platform capabilities using Flutter and React Native, and intuitive mobile UI/UX design. From strategy to maintenance, we ensure your mobile presence is powerful and pervasive.",
    ],
  },
  {
    title: "Desktop",
    description:
      "Harness powerful desktop applications designed to deliver superior user experiences.",
    services: [
      "Develop custom desktop apps for Windows and MacOS, leveraging native .Net, Flutter, and Electron for cross-platform solutions. Our focus on desktop UI/UX design ensures a seamless and engaging user interface.",
    ],
  },
  {
    title: "UI/UX",
    description:
      "Crafting user-centered design strategies that drive engagement and satisfaction.",
    services: [
      "Elevate your digital presence with our UI/UX design services, encompassing user research, wireframing, prototyping, and user testing. We're dedicated to creating intuitive and impactful user experiences.",
    ],
  },
  {
    title: "QA & Testing",
    description:
      "Ensuring your software solutions are robust, reliable, and secure.",
    services: [
      "Comprehensive quality assurance and testing services, including functional, performance, security, and compatibility testing. We guarantee your software is fully optimized and ready for deployment.",
    ],
  },
  {
    title: "DevOps",
    description:
      "Empower your development and operations teams to achieve peak efficiency.",
    services: [
      "Implement DevOps best practices, including continuous integration, continuous delivery, and infrastructure as code. Our DevOps solutions ensure seamless collaboration and rapid delivery.",
    ],
  },
  {
    title: "Cloud Monitoring",
    description: "Ensuring Peak Performance and Reliability in the Cloud.",
    services: [
      "Elevate your AWS infrastructure with our custom monitoring tool, providing real-time insights and proactive anomaly detection. Ensure seamless operations and peak performance with our targeted, efficient monitoring services.",
    ],
  },
  {
    title: "Blockchain",
    description:
      "Secure your digital transactions and data with industry leading blockchain solutions.",
    services: [
      "Implement blockchain technology to secure your digital assets, streamline transactions, and ensure data integrity. Our blockchain solutions are designed to revolutionize your digital operations.",
    ],
  },
  {
    title: "Cybersecurity",
    description:
      "Protect your digital assets and operations with cloud cybersecurity services.",
    services: [
      "Comprehensive cybersecurity services, including risk assessments, threat detection, incident response, and compliance. We're dedicated to safeguarding your digital infrastructure.",
    ],
  },
  {
    title: "Operations",
    description:
      "Optimizing Your Organization's Path to Innovation and Efficiency.",
    services: [
      "Maximize your team's efficiency and innovation with our consulting services.  We focus on optimizing people management, agile processes, and tool selection to boost productivity and scalability in the tech landscape.",
    ],
  },
];

export const defaultOfferings2 = [
  {
    title: "Cloud Services",
    description:
      "Elevate your business with scalable, secure, and innovative cloud services.",
    services: [
      "Infrastructure as a Service (IaaS): AWS, Azure, Google Cloud",
      "Platform as a Service (PaaS): Heroku, AWS Elastic Beanstalk, Google App Engine",
      "Software as a Service (SaaS) Development: Custom solutions for CRM, ERP, and more",
      "Cloud Migration and Management: Seamless transition with zero downtime",
      "Cloud Security: Comprehensive security assessments, compliance checks, and encryption services",
      "Disaster Recovery and Backup: Ensure business continuity with cloud-based recovery solutions",
    ],
  },
  {
    title: "Web Development Services",
    description: "Creating impactful web experiences that drive growth.",
    services: [
      "Frontend Development: React, Angular, Vue.js for dynamic and responsive designs",
      "Backend Development: Node.js, Django, Ruby on Rails for robust server-side solutions",
      "E-Commerce Platforms: Shopify, Magento, WooCommerce for scalable online stores",
      "Content Management Systems (CMS): WordPress, Drupal, Joomla for versatile content management",
      "API Development and Integration: RESTful services, GraphQL, third-party API integrations",
      "Web Performance Optimization: Speed enhancements, SEO, and analytics for optimal user engagement",
    ],
  },
  {
    title: "Mobile App Development",
    description:
      "Innovative mobile solutions to connect with your audience on the go.",
    services: [
      "Native App Development: Swift (iOS), Kotlin (Android) for high-performance apps",
      "Cross-Platform Development: Flutter, React Native for cost-effective app solutions",
      "Mobile UI/UX Design: Engaging and intuitive design principles for the best user experience",
      "Mobile App Strategy: Market analysis, competitor review, and launch strategy",
      "App Maintenance & Support: Ongoing updates, performance monitoring, and customer support",
      "Enterprise Mobility Solutions: Custom mobile applications to streamline business processes",
    ],
  },
];
